import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import '../../styles/filosofia.scss'

import FilosofiaImgW from '../../images/filosofia.webp'
import FilosofiaImg from '../../images/filosofia.png'

export default function Filosofia({ location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Filosofía | Sobre Nosotros" />
			<div className="filosofia">
				<Link to="/#nosotros" className="back">
					&larr;Volver
				</Link>
				<div className="filosofia__title">
					<div className="filosofia__texture" />
					<h1>FILOSOFÍA</h1>
				</div>
				<div className="filosofia__content">
					<div className="filosofia__content__leftbar" />
					<p>
						Tenemos como principio fundamental la estructuración y optimización de espacios sostenibles a
						través del diseño y la innovación. Creemos firmemente en la funcionalidad que el diseño debe
						tener sobre la arquitectura. Es por eso que nuestros proyectos inmobiliarios siempre están
						pensados para responder a una época de modernidad y conectividad.{' '}
					</p>
					<div className="filosofia__content__rightbar" />
				</div>
				<div className="filosofia__footer">
					<picture>
						<source srcSet={FilosofiaImgW} type="image/webp" />
						<source srcSet={FilosofiaImg} type="image/png" />
						<img src={FilosofiaImg} alt="Filosofía Grupo Ínsula" />
					</picture>
					<div className="filosofia__footer__right">
						<div className="filosofia__footer__right__item filosofia__footer__right__item--1">
							<p className="over">
								<span>Habitamos e</span>n un espacio <br />
								<span>
									<strong>sólido, funci</strong>
								</span>
								<strong>onal y moderno</strong>
							</p>

							<div className="filosofia__footer__right__bar" />
						</div>

						<div className="filosofia__footer__right__item filosofia__footer__right__item--2">
							<p className="over">
								<span>Enmarcamos</span> cada lugar <strong>con</strong>
								<br />
								<span>
									<strong>ideas disruptivas</strong>
								</span>
							</p>

							<div className="filosofia__footer__right__bar" />
						</div>

						<div className="filosofia__footer__right__item filosofia__footer__right__item--3">
							<p className="over">
								<span>Promovemos l</span>a coexistencia {''}
								<br />
								<span>
									entre <strong>confort</strong>
								</span>
								<strong> y sostenibilidad</strong>
							</p>

							<div className="filosofia__footer__right__bar" />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
